import ErpProductList from "./list";
import ErpProductShow from "./show";
import ErpProductNew from "./new";


if (document.body.getAttribute("data-current-path") === "erp/products") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new ErpProductList();
    } else if(action === "show") {
        new ErpProductShow();
    } else if(action === "new") {
        new ErpProductNew();
    }
}